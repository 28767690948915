<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="formAdd" :rules="ruleCar">
      <el-form-item label="车牌" :label-width="formLabelWidth" prop="carNum">
        <el-select
            v-model="formAdd.carId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入车牌关键字"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="pickCarNum"
            v-if="disabled== false"
        >
          <el-option
              v-for="item in carList"
              :key="item.id"
              :label="item.carNum"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input v-model="formAdd.carNum" autocomplete="off" placeholder="车牌信息"   maxlength="6"  :disabled="disabled" v-if="disabled== true"></el-input>
      </el-form-item>
      <el-form-item label="负责人" :label-width="formLabelWidth" prop="chargerName">
        <el-select v-model="formAdd.inviteId" placeholder="请选择负责人"  filterable >
          <el-option
              v-for="item in hList"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
              @change="pickCharger"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="当前年检时间" :label-width="formLabelWidth" prop="currentInspectTime">
        <el-date-picker
            v-model="formAdd.currentInspectTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择年检时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="年检金额" :label-width="formLabelWidth" prop="amount">
        <el-input v-model="formAdd.amount" autocomplete="off" placeholder="请输入年检金额"  type="number" maxlength="6"></el-input>
      </el-form-item>
      <el-form-item label="下次年检时间" :label-width="formLabelWidth" prop="nextInspectTime">
        <el-date-picker
            v-model="formAdd.nextInspectTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择下次年检时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="年检金额" :label-width="formLabelWidth" prop="remindDay">
        <el-radio-group v-model="formAdd.remindDay">
          <el-radio-button label="15">提前15天</el-radio-button>
          <el-radio-button label="30">提前30天</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" >
        <el-input v-model="formAdd.remark" autocomplete="off" placeholder="请输入备注"
                  type="textarea"
                  rows="6"
                  maxlength="200"
                  show-word-limit
                  resize="none"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getCarList, getResponsible} from "@/utils/api/dict";
import {detailInspection} from "@/utils/api/car";
export default {
  name: "EditInspection",
  data(){
    var checkPlate = (rule, value, callback) => {
      let reg_plate =  /^[沪A-Z]{1}[A-Z]{1}(([A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)|([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/; //普通车牌 | 新能源货车 | 新能源小客车 车牌校验规则
      if (!reg_plate.test(value)) {
        callback(new Error("请输入正确的车牌"));
      } else {
        callback();
      }
    };
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if (value.length > 20) {
        callback(new Error("输入的姓名过长"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入中文或英文"));
      } else {
        callback();
      }
    };
    return{
      edit:0,//0 新增 ，1编辑
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'',
      formLabelWidth:"110px",
      labelPosition:"right",
      hList:[],
      carList:[],
      value: [],
      list: [],
      loading: false,
      disabled:false,
      formAdd:{
        carId:"",//车牌
        carNum:"",//车牌
        inviteId:"",//负责人
        chargerName:"",//负责人
        currentInspectTime:"",//年检时间
        amount:"",//年检金额
        nextInspectTime:"",//下次年检时间
        remark:"",//备注
        remindDay:"15",//逾期提醒时间
      },
      ruleCar:{
        carNum:[
          { required: true, message: "请输入车牌号码", trigger: 'blur' },
          { validator: checkPlate, trigger: ['blur']  }
        ],
        inviteId:[
          { required: true, message: "请选择负责人", trigger: 'blur' },
        ],
        currentInspectTime:[
          { required: true, message: "请选择年检日期", trigger: 'blur' },
        ],
        amount:[
          { required: true, message: "请输入年检金额", trigger: 'blur' },
        ],
        nextInspectTime:[
          { required: true, message: "请选择下次年检时间", trigger: 'blur' },
        ],
        remindDay:[
          { required: true, message: "请选择预提提醒时间", trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.getResponsible()
  },
  methods:{
    showEdit(row) {
      if (!row) {
        this.title = "新增年检记录";
        this.edit=0;
        this.dialogFormVisible = true;
      } else {
        this.title = "编辑年检记录";
        this.edit=1;
        this.getDetail(row.yearlyInspectionId)
      }

    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          if (this.edit==0){
            this.$emit('add',this.formAdd)
          }else{
            this.$emit('edit',this.formAdd)
          }
          this.dialogFormVisible = false
          this.$refs['form'].resetFields()
          this.form = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },
    getDetail(id){
      let form ={
        id:id
      }
      detailInspection(form).then(res=>{
        this.disabled= true
        this.formAdd = Object.assign({}, this.formAdd, res.data);
        this.dialogFormVisible = true;
      })
    },
    pickCarNum(cid){
      let obj = {};
      obj =  this.carList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
        return item.id == cid;//筛选出匹配数据
      });
      this.formAdd.carNum = obj.carNum;
    },
    pickCharger(id){
      let obj = {};
      obj =  this.carList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
        return item.id == id;//筛选出匹配数据
      });
      this.formAdd.chargerName = obj.realName;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
    getCarList(key){
      let form={
        carNum:key
      }
      getCarList(form).then(res=>{
        this.carList = res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.el-select{
  width: 100%;

}
</style>
