<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">车辆年检</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">车辆年检</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号"   v-model="keywords">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled ></el-button>
            </template>
          </el-input>
          <el-button type="primary" size="small" @click="toSearch">查询</el-button>
          <el-button type="primary" plain size="small" @click="addNew">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="carNum"
              label="车牌号"
              width="300">
          </el-table-column>
          <el-table-column
              prop="chargeName"
              label="负责人"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="currentInspectTime"
              label="年检时间"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="nextInspectTime"
              label="下次年检"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="amount"
              label="金额"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="remindDay"
              label="逾期时间"
              min-width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.remindDay*1>0" class="blue">{{scope.row.remindDay}}日后逾期</span>
              <span v-if="scope.row.remindDay*1<0" class="red">逾期{{ Math.abs(scope.row.remindDay) }}天</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  size="small"
                  @click="toEdit(scope.row)">编辑
              </el-button>
              <el-button type="text" size="small" @click="toDelete(scope.row)" class="red">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <edit-inspection ref="new" @add="saveNew" @edit="editOne"></edit-inspection>
    <delete-confirm ref="delete" :content="content" @save="deleteOne"></delete-confirm>
  </div>
</template>

<script>
import EditInspection from "@/components/EditInspection";
import DeleteConfirm from "@/components/DeleteConfirm";
import {addInspection, editInspection, getInspection,delInspection} from "@/utils/api/car";
export default {
  name: "inspection",
  components:{
    EditInspection,
    DeleteConfirm
  },
  data(){
    return{
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      tableData: [], //表格数据
      dealOne: '', //编辑数据,
      keywords: "" ,//搜索关键字,
      content:"",
    }
  },
  created() {
    this.getList()
  },
  methods:{
    home(){
      this.goHome()
    },
    addNew(){
      this.$refs['new'].showEdit()
    },
    toEdit(row){
      this.$refs['new'].showEdit(row)
    },
    toDelete(row) {
      this.dealOne= row
      this.content="是否将车辆"+row.carNum+"的车险记录删除"
      this.$refs['delete'].showEdit()
    },
    toSearch(){
      this.pageNo=1
      this.getList()
    },
    getList(){
      let form={
        rows:this.pageSize,
        page: this.pageNo,
      }
      if (this.keywords!=""){
        form.carNum= this.keywords
      }
      getInspection(form).then(res=>{
        if(res.code==0){
          this.tableData= res.data.records
          this.total=res.total
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    saveNew(data){
      addInspection(data).then(res=>{
        if(res.code==0){
          this.$baseMessage('添加成功','success')
          setTimeout(() => {
            this.loading = false;
            this.getList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    editOne(data){
      editInspection(data).then(res=>{
        if(res.code==0){
          this.$baseMessage('修改成功','success')
          setTimeout(() => {
            this.loading = false;
            this.getList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    deleteOne(){
      let form={
        id:this.dealOne.yearlyInspectionId
      }
      delInspection(form).then(res=>{
        if (res.code==0){
          this.$baseMessage('删除成功','success')
          setTimeout(() => {
            this.loading = false;
            this.getList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;
  .line {
    height: 90px;
    line-height: 90px;
    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input {
        font-size: 12px;
        margin-right: 12px;
        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }

        .el-input__prefix {
          width: 40px;
          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

</style>
